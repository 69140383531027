import $ from "jquery";
import slick from "slick-carousel";

window.jQuery = $;

$().ready(function () {
  slick_carousels();
  initGoogleMapping();
  setSlideWidth();
});

function setSlideWidth() {
  let carousels = $(".developments-carousel");
  if (carousels.length) {
    let carousel = carousels[0];
    let width = $(carousel).outerWidth();
    let slides = ".developments-carousel .slick-slide";
    console.log(slides.length);
    if (slides.length) {
      $(slides).css({
        "min-width": `${width}px !important;`,
        "max-width": `${width}px !important;`,
      });
    }
  }
}

function slick_carousels() {
  $(".featured-homes-carousel").slick({
    arrows: true,
    dots: true,
    autoplay: false,
    autoplaySpeed: 8000,
    mobileFirst: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<button class="slick-arrow slick-next"></button>',
    prevArrow: '<button class="slick-arrow slick-prev"></button>',
  });

  $(".testimonials-carousel").slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 8000,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

  $(".banner-carousel").slick({
    arrows: true,
    autoplay: false,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<button class="slick-arrow slick-next"></button>',
    prevArrow: '<button class="slick-arrow slick-prev"></button>',
  });

  $(".key-points-carousel").slick({
    dots: true,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 4000,
    slidesToScroll: 1,
    slidesToShow: 1,
  });

  $(".wise-boxes-carousel").slick({
    arrows: true,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: '<button class="slick-arrow slick-next"></button>',
    prevArrow: '<button class="slick-arrow slick-prev"></button>',
  });

  $(".developments-carousel").slick({
    arrows: false,
    dots: true,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  });
}

/**
 * @description centers the map on the current marker or in the center of all markers present
 *
 * @param {*} map the JSON map object being worked on
 */
let centerMap = (map) => {
  let bounds = new google.maps.LatLngBounds();
  $.each(map.markers, function (i, marker) {
    let latlng = new google.maps.LatLng(
      marker.position.lat(),
      marker.position.lng()
    );
    bounds.extend(latlng);
  });
  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter());
    map.setZoom(map.zoom);
  } else {
    map.fitBounds(bounds);
  }
};

/**
 * @description adds a new marker to the map being created
 *
 * @param {*} $marker the jQuery HTML marker to be added
 * @param {*} map the JSON map object to be operated on
 */
let addMarker = ($marker, map) => {
  let latlng = new google.maps.LatLng(
    $marker.attr("data-lat"),
    $marker.attr("data-lng")
  );

  let pointer = "pointer.svg";
  if ($($marker).hasClass("marker-green")) {
    pointer = "pointer-green.svg";
  }
  let pointerPath = `/app/themes/frogspark/img/${pointer}`;

  let icon = {
    url: pointerPath,
    scaledSize: new google.maps.Size(48, 48),
  };

  let marker_id = $marker.attr("id");
  let marker = new google.maps.Marker({
    icon: icon,
    map: map,
    position: latlng,
    id: marker_id,
    zIndex: 1,
  });
  if ($($marker).hasClass("marker-green")) {
    marker.setZIndex(2);
  }

  map.markers.push(marker);

  google.maps.event.addListener(marker, "click", function () {
    console.log(marker);
    let locID = marker.id;
    console.log(locID);
    if (locID) {
      $(".developments-carousel").addClass("d-none");
      $(".map-development").addClass("hidden");

      let locationTile = $(`.map-development[data-location=${locID}]`);
      if (locationTile.length) {
        locationTile.removeClass("hidden");
      }
    }

    let locationSlider = $(`.developments-carousel#location-${locID}`);
    if (locationSlider.length) {
      locationSlider.removeClass("d-none");
      locationSlider.slick("resize");
    }
  });
};

/**
 * @description creates a new map at the location of the jQuery element passed in
 *
 * @param {*} $el the jQuery element to create a map aroudn
 */
let newMap = ($el) => {
  let $markers = $el.find(".marker");
  let args = {
    center: new google.maps.LatLng(0, 0),
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    styles: [
      {
        featureType: "all",
        elementType: "labels.text",
        stylers: [
          {
            color: "#878787",
          },
        ],
      },
      {
        featureType: "all",
        elementType: "labels.text.stroke",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [
          {
            color: "#f9f5ed",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "all",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "all",
        stylers: [
          {
            color: "#aee0f4",
          },
        ],
      },
    ],
    zoom: 14,
    clickableIcons: false,
  };
  let map = new google.maps.Map($el[0], args);
  map.markers = [];
  $markers.each(function () {
    addMarker($(this), map);
  });
  centerMap(map);
  return map;
};

/**
 * @description convenience function for running all code required to generate a single (or multiple) maps on a page
 */
let initGoogleMapping = () => {
  $(".tile-map").each(function () {
    newMap($(this));
  });
};
